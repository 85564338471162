
import Vue from "vue";
import { VueLoading } from "vue-loading-template";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    VueLoading,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "rgba(0, 0, 0, 0.33)",
    },
  },
});

interface Props {
  backgroundColor: string;
}

interface Data {}

interface Computed {}

interface Methods {}
